import React from "react";
import Header from "../Component/Header";
import ImageMarquee from "./ImageMarquee";
import Map from "./Map";
import Services from "./Services";
import Plans from "./Plans";
import Work from "./Work";
import Client from "./Client";
import Faq from "./Faq";
import Footer from "./Footer";
import AnimatedItem from "../Component/AnimatedItem";
import Experience from "./Experience";
import Internet from "./Internet";

function HeroSection() {
  return (
    <div>
      <Header />
      <div className="block lg:flex justify-between w-full container mx-auto">
        <div className="p-10 w-[100%] lg:w-[50%] flex flex-col sm:justify-evenly">
          <AnimatedItem>
          <h1 className="h1 text-[#1d2a3e] text-xl md:text-4xl lg:text-7xl lg:pt-[5rem] font-semibold">
          Save More, Accelerate Quickly, No Contracts
          </h1>
          </AnimatedItem>
          <AnimatedItem>
          <h3 className="h3 text-xl font-semibold pt-4">
            Stay connected with all of your devices.
          </h3></AnimatedItem>
          <AnimatedItem>
          <p className="text-lg">
          As the leading provider of high-speed internet, TV, home phone, and mobile services, we deliver top-quality service at unbeatable prices right in your area.
          </p></AnimatedItem>
          <AnimatedItem>
          <ul className="list-disc pt-4 custom-list">
            <li className="list-disc">125+ channels in HD and on demand</li>
            <li className="list-disc">
              Up to 1 Gbps internet speed with no data caps
            </li>
            <li className="list-disc">
              Affordable home phone with no added fees or dropped calls
            </li>
          </ul></AnimatedItem>
          <AnimatedItem>
          <div className="flex pt-4 w-full">
            <button className="btn bg-[#f2ae1b] hover:bg-white hover:text-[#f2ae1b] hover:border-2  hover:border-[#f2ae1b] hover:scale-y-125 px-4 py-2 rounded-md text-white shadow-md">
              Call Us
            </button>
            <button className=" hover:bg-white hover:text-[#f2ae1b] hover:border-2  hover:border-[#f2ae1b] hover:scale-y-125 btn bg-[#f2ae1b] px-4 py-2 rounded-md ml-4 text-white shadow-md">
              Click to Connect
            </button>
          </div></AnimatedItem>
        </div>

        <div className="w-[100%] lg:w-[50%]">
          <AnimatedItem>
          <img src="/assets/hero1.svg" className="p-8" />
          </AnimatedItem>
        </div>
      </div>
      <div className="bg-gradient-to-r from-cyan-500 to-blue-500 rounded-t-[100px] info-pattern ">
         
        <div className="block lg:flex w-full justify-around pt-16 pb-16 border-t-white rounded-tr-[6rem] container mx-auto">
        <AnimatedItem>
          <div className=" m-8">
            <h2 className="text-2xl font-bold text-white">
            Save up to $500 on switching costs with us.
            </h2>
            <p className="text-white text-sm pt-2">
            Enjoy the service you want, minus the hassle of a long-term contract.
            </p>
          </div></AnimatedItem>
          <AnimatedItem>
          <div className=" m-8">
            <h2 className="text-2xl font-bold text-white">
            No contracts, no fees, 24/7 assistance.
            </h2>
            <p className="text-white text-sm pt-2">
            Search providers, compare plans, and order securely with our patented technology.
            </p>
          </div></AnimatedItem>
          <AnimatedItem>
          <div className=" m-8">
            <h2 className="text-2xl font-bold text-white">
            30-day money-back guarantee
            </h2>
            <p className="text-white text-sm pt-2">
            Backed by 20 years and 100+ experts, we’re here to assist you.
            </p>
          </div></AnimatedItem>
        </div>
      </div>
      <ImageMarquee />
      <Map />
      <Services />
      <Experience/>
      <Plans />
      <Work />
      <Internet/>
      <Client />
      <Faq />
      <Footer />
    </div>
  );
}

export default HeroSection;
